import React, { useCallback, useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import ItemRow from './ItemRow';
import { AUdollar } from '../utils/formats';

export function UnitCostAnswers(props) {
    const data = props.data;
    const spend = props.spend;

    const [total, setTotal] = useState(0)
    const [subTotalKeyValues, setSubtotalKeyValues] = useState(new Map());

    // Refresh on change dataset
    useEffect(() => {
        setSubtotalKeyValues(new Map(data.map(item => [item.name, 0])))
    }, [data])

    // Receive updated row and update main totals array
    const handleUpdateRow = useCallback(updatedSubTotal => {
        setSubtotalKeyValues(prevSubTotalKeyValues => {
            const newList = new Map(prevSubTotalKeyValues);
            newList.set(updatedSubTotal.Item, updatedSubTotal.Cost);
            return newList;
        });
    }, [])

    // Do the calculation
    useEffect(() => {
        let sumValues = 0;
        for (const [key, value] of subTotalKeyValues) {
            console.log(`${key} = ${value}`);
            sumValues += value;
        }
        setTotal(sumValues);
    }, [subTotalKeyValues])

    return (
        <Row className='unit-cost-answers'>
            <table className='styled-table' id='unit-cost-budget-table'>
                <colgroup>
                    <col span="1" style={{ width: "6em" }} />
                    <col span="1" />
                    <col span="1" style={{ width: "15em", textAlign: "right" }}/>
                </colgroup>
                <thead>
                    <tr>
                        <th>Category</th>
                        <th>Items</th>
                        <th>Budgeting <br /> (Remaining: <span className='numbers'>{AUdollar.format(spend - total)})</span></th>
                    </tr></thead>
                <tbody>
                    {data.map((item) => (
                        <tr key={item.name} className='parentListItem'>
                            <ItemRow item={item} spend={spend} setThisSubtotalKeyValue={handleUpdateRow} />
                        </tr>
                    ))}
                </tbody>
                <tfoot><tr><td></td><td></td><td style={{textAlign: "right"}}>Total Spent: <span className={total > spend ? 'warning numbers' : 'numbers'}> {AUdollar.format(total)}</span></td></tr></tfoot>
            </table>
        </Row>
    );
}
