import { React, useState, useEffect } from 'react';
import CategoryEmoji from './CategoryEmoji';
import {AUdollar} from '../utils/formats';

function ItemRow(props) {
    const {item, spend, setThisSubtotalKeyValue} = props;
    const [itemTotal, setTotal] = useState(0);
    const [chosenItem, setChosenItem] = useState('');
    const [maxQty, setMaxQty] = useState(0);
    const [chosenItemCost, setChosenItemCost] = useState(0);
    const [chosenQty, setChosenQty] = useState(0);
    
    const setSelection = (cost) => {
        return () => {
            setChosenItem(cost.costType);
            setMaxQty(Math.floor(spend / cost.cost));
            setChosenItemCost(cost.cost);
        }
    }
    const handleQty = (event) => {
        setChosenQty(parseInt(event.target.value));
    }

    useEffect(() => {
        if (spend < chosenItemCost) {
            setChosenItem('')
        }
    }, [chosenItemCost, spend])
    
    useEffect(() => {
        const thisTotal = chosenQty * chosenItemCost
        setTotal(thisTotal);
        const thisSub = {Item: props.item.name, Cost: thisTotal}
        setThisSubtotalKeyValue(thisSub)
    }, [chosenQty, chosenItemCost, props.item.name, setThisSubtotalKeyValue])

    return (
        <>
            <td>
                <CategoryEmoji category={item.emoji} />
            </td>
            <td>{item.nounForEach} {item.name}:
                <ul>
                    {item.costs.map((cost) => (
                        Math.floor(spend / cost.cost) > 0 ?
                            <li key={item.name + cost.costType}>
                                <input type='radio' id={item.name + cost.costType} name={item.name} value={cost.costType} onChange={setSelection(cost)}/>
                                <label htmlFor={item.name + cost.costType}>{`${cost.costType} at ${AUdollar.format(cost.cost)} each`}</label></li>
                            :
                            <li key={item.name + cost.costType}>{`You need to save ${spend} at least ${Math.ceil(cost.cost / spend)} times to buy 1 ${cost.costType} here. (${AUdollar.format(cost.cost)})`}</li>
                    ))}
                </ul></td>
            <td className='qtyChooser'><span className='cost-type'>{chosenItem}</span> X <input type='number' min={0} max={maxQty} disabled={chosenItem === ''} onChange={handleQty}/> = <span className='numbers'>{AUdollar.format(itemTotal)}</span></td>
        </>
    )
}

export default ItemRow;